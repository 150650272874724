import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Image,
  VStack,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import '../../node_modules/video-react/dist/video-react.css';
import { Player } from 'video-react';
export default NiceModal.create(({ src = '' }) => {
  const modal = useModal();
  return (
    <>
      <Modal
        id="concurso"
        size="md"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <VStack>
              <Image src={src} style={{ margin: '0 auto' }} />
              <Button
                onClick={() => modal.hide()}
                size="lg"
                width={'351px'}
                px={6}
                colorScheme={'pink'}
                bg={'pink.400'}
                _hover={{ bg: '#95def7' }}
              >
                Cerrar
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

import { useState, useEffect } from 'react';

import CustomWheel from './CustomWheel';
import NiceModal from '@ebay/nice-modal-react';

import { Flex, Center, VStack, Spinner } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import Ipbase from '@everapi/ipbase-js';

const Ruleta = () => {
  const [pais, setPais] = useState();
  const ipBase = new Ipbase('fgAGxKNkkEYU27QzoajdoqqnX36SgADRQnsospwA');
  const location = useLocation();
  ipBase.info().then(res => {
    setPais(res.data.location.country.alpha2);
  });
  const { response, loading, error } = useAxios({
    method: 'get',
    url:
      '/getruleta/' +
      new URLSearchParams(location.search).get('payment_id') +
      '/' +
      pais,
  });

  const [isReady, setIsReady] = useState(false);
  const [minSext, setMinSext] = useState();
  const [minChat, setMinChat] = useState();

  useEffect(() => {
    if (localStorage.tirada) {
      const tirada_arry = localStorage.tirada.split('|');
      if (
        tirada_arry[1] ===
        new URLSearchParams(location.search).get('payment_id')
      ) {
        const pi = new URLSearchParams(location.search).get('payment_id');
        console.log(tirada_arry[1] === pi);

        NiceModal.show('ruletapremio');
      }
    }
    localStorage.codigo = new URLSearchParams(location.search).get(
      'payment_id'
    );
    //    if (response === 'error') navigate('/');
    if (response !== null) {
      console.log(response);
      console.log(JSON.stringify(response));
      localStorage.minutos = response.minutos; //en orden sexting, chat
      var arrminutos = response.minutos.split('|');
      setMinChat(arrminutos[1]);
      setMinSext(arrminutos[0]);
      console.log(arrminutos[0]);
      setIsReady(true);
    }
  }, [response]);

  return (
    <Flex
      style={{
        height: '100vh',
        backgroundImage: "url('../fondorosa.png')",
        backgroundPosition: 'center',
      }}
    >
      <Center style={{ minWidth: '100%' }}>
        <VStack spacing={3}>
          {loading ? (
            <Spinner />
          ) : (
            isReady && (
              <CustomWheel
                minChat={minChat === undefined ? '' : minChat}
                minSext={minSext === undefined ? '' : minSext}
                paymentid={new URLSearchParams(location.search).get(
                  'payment_id'
                )}
              />
            )
          )}
        </VStack>
      </Center>
    </Flex>
  );
};

export default Ruleta;

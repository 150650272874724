import { motion } from 'framer-motion';
import {
  Box,
  FormControl,
  Stack,
  Heading,
  Text,
  useColorModeValue,
  Center,
} from '@chakra-ui/react';
import React, { useState, useContext, useEffect } from 'react';
import { ReactComponent as MercadoPagoLogo } from './assets/mercado-pago.svg';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { userContext } from '../AppContext';
import Ipbase from '@everapi/ipbase-js';

type ForgotPasswordFormInputs = {
  email: string,
};

export function IntegracionMP(): JSX.Element {
  const ipBase = new Ipbase('fgAGxKNkkEYU27QzoajdoqqnX36SgADRQnsospwA');
  const navigate = useNavigate();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    const algo = query.get('fromtg');
    if (!algo) navigate('/');
    localStorage.email = algo;
    localStorage.logged = true;
  }, []);

  const { user, handleUserUpdate, config } = useContext(userContext);
  const [url_sitio, setUrl_sitio] = useState('https://putibot.app/auth');
  //const { url_sitio, setUrl_sitio } = useState('http://modelo.estoy.live/');
  const conectarMercadoPago = () => {
    if (localStorage.pais === undefined) {
      ipBase.info().then(response => {
        const pais = response.data.location.country.alpha2;

        if (pais === 'UY') {
          localStorage.pais = 'UY';
          window.location.href = `https://auth.mercadopago.com/authorization?client_id=1817367863397264&response_type=code&platform_id=mp&state=${localStorage.email}|UY&redirect_uri=${url_sitio}`;
        }
        if (pais === 'AR') {
          localStorage.pais = 'AR';
          window.location.href = `https://auth.mercadopago.com/authorization?client_id=3237931361464200&response_type=code&platform_id=mp&state=${localStorage.email}|AR&redirect_uri=${url_sitio}`;
        }
      });
    } else {
      const pais = localStorage.pais;
      if (pais === 'UY') {
        window.location.href = `https://auth.mercadopago.com/authorization?client_id=1817367863397264&response_type=code&platform_id=mp&state=${localStorage.email}|UY&redirect_uri=${url_sitio}`;
        localStorage.pais = 'UY';
      }
      if (pais === 'AR') {
        window.location.href = `https://auth.mercadopago.com/authorization?client_id=3237931361464200&response_type=code&platform_id=mp&state=${localStorage.email}|AR&redirect_uri=${url_sitio}`;
        localStorage.pais = 'AR';
      }
    }
    /*
      if (resultado.countryCode === 'UY') {
        window.location.href = `https://auth.mercadopago.com/authorization?client_id=1817367863397264&response_type=code&platform_id=mp&state=${localStorage.email}|UY&redirect_uri=${url_sitio}`;
        localStorage.pais = 'UY';
      }
      if (resultado.countryCode === 'AR') {
        window.location.href = `https://auth.mercadopago.com/authorization?client_id=3237931361464200&response_type=code&platform_id=mp&state=${localStorage.email}|AR&redirect_uri=${url_sitio}`;
        localStorage.pais = 'AR';
      }
      */
  };
  /*const conectarPaypal = () => {
    window.location.href = `https://auth.mercadopago.com/authorization?client_id=2401159577768886&response_type=code&platform_id=mp&state=${(
      Math.random(0, 10) * 10000
    ).toFixed(0)}&redirect_uri=${url_sitio}`;
  };*/
  return (
    <Box
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('brand.250', 'gray.800')}
      p={16}
    >
      <Stack
        spacing={1}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={1}
        onClick={() => conectarMercadoPago()}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          <Center> Conecta tu MercadoPago</Center>
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}
        >
          <Center>
            <small>Una unica vez y comienza a cobrar tus Contenido!</small>
          </Center>
        </Text>
        <FormControl id="email">
          <Box
            style={{ cursor: 'pointer' }}
            variant={'solid'}
            backgroundColor={'transparent'}
            size={'sm'}
            mr={1}
            p={1}
          >
            <AddIcon />
          </Box>

          <Box
            style={{ cursor: 'pointer' }}
            variant={'solid'}
            backgroundColor={'#f0f0f0'}
            size={'sm'}
            mr={5}
            p={2}
          >
            <MercadoPagoLogo width="180px" />
          </Box>
        </FormControl>
      </Stack>
    </Box>
  );
}

export default function Integracion({ isVisible }) {
  const [existe, setExiste] = useState(true);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <IntegracionMP />
    </motion.div>
  );
}

import AnimatedRouters from './components/AnimatedRouters';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { userContext } from './AppContext';
import NiceModal from '@ebay/nice-modal-react';
import ModalCropper from './components/ModalCropper';
import InfoModal from './components/InfoModal';
import VideoModal from './components/VideoModal';
import QrModal from './components/QrModal';
import GifModal from './components/GifModal';
import ModalRuletaPremio from './components/ModalRuletaPremio';

NiceModal.register('cropper', ModalCropper);
NiceModal.register('infomodal', InfoModal);
NiceModal.register('videomodal', VideoModal);
NiceModal.register('concurso', GifModal);
NiceModal.register('qrmodal', QrModal);
NiceModal.register('ruletapremio', ModalRuletaPremio);

export default function App() {
  const { user } = useContext(userContext);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
  };

  useEffect(() => {
    var srcArray = [];
    srcArray.push('qr.jpg');
    srcArray.push('upa.PNG');
    srcArray.push('workin.PNG');
    srcArray.push('concurso.gif');
    srcArray.push('importante.gif');

    cacheImages(srcArray);

    localStorage.logged = false;
    /* var currentUser = {};
    if (localStorage.getItem('user_id')) {
      currentUser = { user_id: localStorage.getItem('user_id') };
    }
    if (localStorage.getItem('nickname')) {
      currentUser = {
        ...currentUser,
        nickname: localStorage.getItem('nickname'),
      };
    }
    if (localStorage.getItem('hash')) {
      currentUser = { ...currentUser, hash: localStorage.getItem('hash') };
    }*/
  }, []);
  return (
    <>
      <NiceModal.Provider>
        <BrowserRouter>
          <AnimatedRouters />
        </BrowserRouter>
      </NiceModal.Provider>
    </>
  );
}

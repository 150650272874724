import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { userContext } from '../AppContext';

import * as Yup from 'yup';

export default function InicioLoginRegister() {
  const {
    config,
    user,
    handleUserUpdate,
    handleActiveNavLink,
    isLogged,
    setIsLoged,
  } = useContext(userContext);
  const [showPassword, setShowPassword] = useState(false);
  const [validar, setValidar] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const [localhost, setLocalhost] = useState('http://localhost:3000');

  useEffect(() => {
    handleActiveNavLink(1);
  }, []);

  useEffect(() => {
    if (localStorage.nickname)
      handleUserUpdate(user, 'nickname', localStorage.nickname);
    if (localStorage.hash) handleUserUpdate(user, 'hash', localStorage.hash);
    if (localStorage.email) handleUserUpdate(user, 'email', localStorage.email);
    if (localStorage.frase) handleUserUpdate(user, 'frase', localStorage.frase);
    if (localStorage.avatar)
      handleUserUpdate(user, 'avatar', localStorage.avatar);
    if (localStorage.precios)
      handleUserUpdate(user, 'precios', localStorage.precios);
  }, []);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Correo mal formado').required('Requerido'),
    }),
    onSubmit: value => {
      setOnLoading(true);
      localStorage.setItem('email', value.email);
      handleUserUpdate(user, 'email', value.email);

      axios
        // .post('https://server-mb9m.onrender.com/telegram-registro', {
        .post('http://localhost:3005/telegram-registro', {
          email: value.email,
          password: value.password,
        })
        .then(res => {
          setOnLoading(false);
          if (res.data.result === 1) {
            // console.log(user);
            setIsLoged(true);
            localStorage.logged = true;
            navigate('/nick-avatar');
          }
          if (res.data.result === -1) {
            alert('error');
          }
          if (res.data.result === 0) {
            localStorage.logged = true;
            navigate('/metodos-de-pago');
          }
        });
    },
  });
  return (
    <Box
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('brand.250', 'gray.800')}
      p={[0, 10, 16]}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={6} px={6}>
        <Box
          as="form"
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl autocomplete="off" id="email" isRequired>
              <FormLabel>Correo:</FormLabel>
              <Input
                autocomplete="false"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={() => setValidar(true)}
              />
              {formik.errors.email && validar ? (
                <div>{formik.errors.email}</div>
              ) : null}
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type={showPassword ? 'text' : 'password'}
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                isLoading={onLoading}
                size="lg"
                bg={'brand.350'}
                color={'black'}
                onClick={formik.handleSubmit}
                _hover={{
                  bg: 'brand.500',
                }}
              >
                Continuar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

import {
  Avatar,
  Box,
  chakra,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';

const testimonials = [
  {
    // name: 'Brandon P.',
    role: 'Me han comprado desde otro país, ¿Porque no veo el pago?',
    content:
      'Si un cliente te compra contenido desde Uruguay, nuestras oficinas en Uruguay cobrarán a tu nombre por tu contenido. Lamentablemente por limitaciones burocráticas de mercadopago no es posible trabajar con la modalidad marketplace entre países cruzados. Por esta razón no verás reflejado en tu saldo, dentro de la aplicación de mercadopago, dicho pago.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '¿Cómo veo cuanto he generado en otros Países?',
    content:
      'Con el comando /saldo puedes consultar tu saldo en otros países. El saldo refleja en tiempo real lo que se ha pagado en otros países por tu contenido. Se muestra en la moneda de tu país. Puedes ver pequeñas variaciones dado que el saldo que tienes en otros países se encuentra en su moneda local y para tu comodidad te lo mostramos con el cambio a tu moneda local, en tiempo real.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '¿Cómo hago para cobrar lo que veo en mi saldo?',
    content:
      'Para cobrar el dinero de tus ventas cruzadas debes haber hecho al menos 40 transacciones con clientes de tu misma localidad. A partir de la transacción número 41 se te depositara automáticamente, toda venta que generes en otro país, desde nuestra cuenta en tu país. Ejemplo: Eres de Argentina y has comenzado a usar el putibot. Alguien de Uruguay te compra contenido. Luego de 40 ventas a Argentinos se te activa el cobro cruzado. Cada mes se cierra la contabilidad de tu saldo en otros países y se te hace un depósito junto con un email detallando el pago, desde nuestra cuenta Mercadopago Argentina.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '¿Que incluye una transacción de las 40 obligatorias?',
    content:
      'Tanto las tiradas de ruleta como la venta de pack de fotos/videos, cuentan como una transacción válida. Como referencia, nuestras usuarias generan en promedio 11 transacciones diarias entre ruleta y venta de contenido',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
];

const backgrounds = [
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
];

function TestimonialCard(props) {
  const { name, role, content, avatar, index } = props;
  return (
    <Flex
      boxShadow={'lg'}
      maxW={'640px'}
      direction={{ base: 'column-reverse', md: 'row' }}
      width={'full'}
      rounded={'xl'}
      p={10}
      justifyContent={'space-between'}
      position={'relative'}
      bg={useColorModeValue('white', 'gray.800')}
      _after={{
        content: '""',
        position: 'absolute',
        height: '21px',
        width: '29px',
        left: '35px',
        top: '-10px',
        backgroundSize: 'cover',
      }}
      _before={{
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        height: 'full',
        maxW: '640px',
        width: 'full',
        filter: 'blur(40px)',
        transform: 'scale(0.98)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        top: 0,
        left: 0,
        backgroundImage: backgrounds[index % 4],
      }}
    >
      <Flex direction={'column'} textAlign={'left'}>
        <chakra.p fontWeight={'bold'} fontSize={14} pb={4}>
          {name}
          <chakra.span fontWeight={'medium'} color={'gray.500'}>
            {' '}
            - {role}
          </chakra.span>
        </chakra.p>
        <chakra.p fontWeight={'medium'} fontSize={'15px'} pb={4}>
          {content}
        </chakra.p>
      </Flex>
    </Flex>
  );
}

export default function MercadoPagoCruzado() {
  return (
    <Flex
      textAlign={'center'}
      pt={10}
      justifyContent={'center'}
      direction={'column'}
      width={'full'}
    >
      <Box width={{ base: 'full', sm: 'lg', lg: 'xl' }} margin={'auto'}>
        <chakra.h3
          fontWeight={'bold'}
          fontSize={20}
          textTransform={'uppercase'}
          color={'pink.500'}
        >
          Cobrar Cruzado
        </chakra.h3>
        <chakra.h1
          py={5}
          fontSize={48}
          fontWeight={'bold'}
          color={useColorModeValue('gray.700', 'gray.50')}
        >
          Preguntas Frecuentes
        </chakra.h1>
        <chakra.h2
          margin={'auto'}
          width={'70%'}
          fontWeight={'medium'}
          color={useColorModeValue('gray.500', 'gray.400')}
        >
          Como cobrar{' '}
          <chakra.strong color={useColorModeValue('gray.700', 'gray.50')}>
            MercadoPago
          </chakra.strong>{' '}
          de Uruguay, Chile o Brasil. Si sos Argentino.
        </chakra.h2>
      </Box>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        spacing={'20'}
        mt={16}
        mx={'auto'}
      >
        {testimonials.map((cardInfo, index) => (
          <TestimonialCard {...cardInfo} index={index} />
        ))}
      </SimpleGrid>
      <Box>
        <Icon viewBox="0 0 40 35" mt={14} boxSize={10} color={'pink.400'}>
          <path
            fill={'currentColor'}
            d="M10.7964 5.04553e-07C8.66112 -0.000123335 6.57374 0.632971 4.79827 1.81922C3.0228 3.00547 1.63898 4.69158 0.82182 6.66433C0.00466116 8.63708 -0.209132 10.8079 0.207477 12.9021C0.624087 14.9964 1.65239 16.9201 3.16233 18.4299L19.1153 34.3828C19.2395 34.5074 19.3871 34.6062 19.5496 34.6736C19.7121 34.741 19.8863 34.7757 20.0622 34.7757C20.2381 34.7757 20.4123 34.741 20.5748 34.6736C20.7373 34.6062 20.8848 34.5074 21.0091 34.3828L36.962 18.4272C38.9319 16.3917 40.0228 13.6636 39.9996 10.8311C39.9764 7.99858 38.8409 5.28867 36.838 3.28573C34.835 1.28279 32.1251 0.147283 29.2926 0.124081C26.4601 0.100879 23.732 1.19184 21.6965 3.1617L20.0622 4.79337L18.4305 3.1617C17.4276 2.15892 16.237 1.36356 14.9267 0.821064C13.6163 0.278568 12.2119 -0.000433066 10.7937 5.04553e-07H10.7964Z"
          />
        </Icon>
      </Box>
    </Flex>
  );
}

import { useState, useRef, useContext } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Box,
  Stack,
  Container,
  SimpleGrid,
  VStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import StyledLink from './StyledLink';
import Cropper from 'react-easy-crop';
import getCroppedImg from './assets/utils/cropImage';
import { userContext } from '../AppContext';

export default NiceModal.create(({ props }) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  const [errMsg, setErrMsg] = useState('');
  const usrRef = useRef();
  const submitBtn = useRef();
  const { user, handleUserUpdate } = useContext(userContext);

  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleKeyPress = e => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      submitBtn.current.click();
    }
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const setAvatar = async () => {
    const tempImg = props.image;
    const tempCanvas = await getCroppedImg(tempImg, croppedArea);

    const img = tempCanvas.toDataURL('image/png');
    handleUserUpdate(user, 'avatar', img);
    modal.resolve();
    modal.hide();
  };

  return (
    <Modal
      id="cropper"
      isOpen={modal.visible}
      onClose={() => modal.hide()}
      size={'full'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton zIndex={999} />
        <Box p={5}>
          <section>
            {errMsg && (
              <Box py={5}>
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Oops!</AlertTitle>
                  <AlertDescription>{errMsg}</AlertDescription>
                </Alert>
              </Box>
            )}
            <Stack spacing={3}>
              <Box>
                <Cropper
                  width="200px"
                  height="200px"
                  image={props.image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </Box>
              <Box>
                <Slider
                  aria-label="slider-ex-5"
                  value={zoom}
                  max={3}
                  min={1}
                  step={0.1}
                  onChangeEnd={val => setZoom(val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
                <Button onClick={setAvatar}> Establecer Avatar </Button>
              </Box>
            </Stack>
          </section>
        </Box>
        <ModalFooter>
          <Container width="100%" p={0}>
            <SimpleGrid columns={{ sm: 2, md: 2 }} pt={10} color="gray.400">
              <Box
                justifyContent="baseline"
                textAlign="right"
                paddingRight={4}
                paddingTop={2}
              ></Box>
              <VStack>
                <Box width="100%"></Box>
                <Box>
                  <StyledLink onClick={() => modal.hide()} fontSize={12} pt={2}>
                    Cancelar
                  </StyledLink>
                </Box>
              </VStack>
            </SimpleGrid>
          </Container>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

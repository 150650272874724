import {
  Avatar,
  Box,
  chakra,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';

const testimonials = [
  {
    // name: 'Brandon P.',
    role: '/start',
    src: 'start.png',
    vid: 'registroymp.mp4',
    content:
      'Registrate en nuestro sistema para comenzar a vender contenido. Ingresa un email y una contraseña, luego dirigete al link que te proporcionamos para conectar tu cuenta de mercadopago.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '/conectar',
    src: 'conectar.png',
    vid: 'conectar.mp4',
    content:
      'Este comando permitira que el bot sepa cual es tu canal/grupo a donde enviar tu pack y ruletas. La primera vez que lo envies te dara un codigo para copiar y pegar en tu canal y si has agregado al bot como administrador en tu canal, entendera que ahi es donde enviara tus ofertas cuando se las pidas (y ruletas)',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '/cargar',
    src: 'cargar.png',
    vid: 'cargar.mp4',
    content: 'Comineza a subir videos y fotos para tus putipacks y ruletas!',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '/putipack',
    src: 'putipack.png',
    vid: 'putipack.mp4',
    content:
      'Con este comando puedes agregar/editar el título, el precio y el número de mensajes que el bot tiene que esperar para enviar tu oferta dentro de tu canal o grupo. De esta forma si no estás activa en tu grupo igual estarás publicitando tu contenido.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '/contenido',
    src: 'contenido.png',
    vid: 'contenido5.mp4',
    content:
      'Comando para ver y editar tu contenido subido. Las miniaturas de tu contenido se muestran con tres botones al pie de cada una. Estos son: Activar, Eliminar, Premio ruleta. Activar/Desactivar es en referencia a que quieres incluir en tu pack para la venta en grupos y canales. Eliminar borra el contenido permanentemente. Premio Ruleta, vincula tu contenido con la ruleta, para los premios "Pack de Fotos" y "Pack de Videos". \n Mira los siguientes 3 ejemplos. \n\n 1 • Activamos contenido para la ruleta \n\n 2 • Activamos/desactivamos items del pack \n\n 3 • Eliminamos de forma permanente casi todas las fotos.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '/ruleta',
    src: 'ruleta2.png',
    vid: 'ruleta2.mp4',
    content:
      'Este comando sirve para configurar tu ruleta. Podrás configurar los minutos para SEXTING y VIDEO CHAT. Son tan solo 3 pasos y tu ruleta estará lista. Recuerda que si tienes una oferta de ruleta activa, debes tener al menos 1 video y 1 foto siempre activos',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '/link',
    src: 'link.png',
    vid: 'link.mp4',
    content:
      'Este comando te entrega un link directo a compra de tu putipack activo. Puedes compartirlo en tus perfiles o persona a persona. Siempre apuntara a tu putipack activo y directo a el pago de mercadopago para el pais de donde el cliente es.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
  {
    // name: 'Brandon P.',
    role: '/saldo',
    src: 'saldo.png',
    vid: 'saldo.mp4',
    content:
      'Si tienes dinero por ventas de mercadopago cruzado, este comando te mostrará dichos montos en tu moneda local. Ten en cuenta que hacemos la conversión de la moneda original de la cuenta, a tu moneda local, para facilitarte los cálculos. Dicho esto, podemos ver variaciones mínimas. Hacemos especial hincapié en que el saldo es fijo y se encuentra en la moneda del país desde donde se inició la transacción, osea donde se encuentra tu cliente. Si has hecho al menos 40 transacciones locales con nosotros, puedes solicitar el depósito de los saldos de ventas al exterior, cada jueves de la semana. Los depósitos están sujetos a disponibilidad en nuestra cuenta local y podría suceder que demore un poco más de lo aquí especificado. Pero nunca más de 3 días hábiles.',
    // avatar:
    //   'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
  },
];

const backgrounds = [
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23B65796'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23332f35'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23b55795'/%3E%3C/svg%3E")`,
];

function TestimonialCard(props) {
  const { name, role, content, avatar, index, src, vid } = props;

  const showVideoModal = () => {
    NiceModal.show('videomodal', {
      videosrc: vid,
      poster: src,
    });
  };
  return (
    <Flex
      boxShadow={'lg'}
      maxW={'640px'}
      direction={{ base: 'column-reverse', md: 'row' }}
      width={'full'}
      rounded={'xl'}
      p={10}
      justifyContent={'space-between'}
      position={'relative'}
      bg={useColorModeValue('white', 'gray.800')}
      _after={{
        content: '""',
        position: 'absolute',
        height: '21px',
        width: '29px',
        left: '35px',
        top: '-10px',
        backgroundSize: 'cover',
      }}
      _before={{
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        height: 'full',
        maxW: '640px',
        width: 'full',
        filter: 'blur(40px)',
        transform: 'scale(0.98)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        top: 0,
        left: 0,
        backgroundImage: backgrounds[index % 4],
      }}
    >
      <Flex direction={'column'} textAlign={'left'}>
        <chakra.p fontWeight={'bold'} fontSize={24} pb={4}>
          {name}
          <chakra.span fontWeight={'medium'} color={'gray.500'}>
            {role}
          </chakra.span>
        </chakra.p>
        <chakra.p fontWeight={'medium'} fontSize={'15px'} pb={4}>
          {content}
        </chakra.p>
        <Box onClick={showVideoModal}>
          <Image src={src} alt="Dan Abramov" />
        </Box>
      </Flex>
    </Flex>
  );
}

export default function AyudaComandos() {
  return (
    <Flex
      textAlign={'center'}
      pt={10}
      justifyContent={'center'}
      direction={'column'}
      width={'full'}
    >
      <Box width={{ base: 'full', sm: 'lg', lg: 'xl' }} margin={'auto'}>
        <chakra.h3
          fontWeight={'bold'}
          fontSize={20}
          textTransform={'uppercase'}
          color={'pink.500'}
        >
          Ayuda PutibotApp
        </chakra.h3>
        <chakra.h1
          py={5}
          fontSize={48}
          fontWeight={'bold'}
          color={useColorModeValue('gray.700', 'gray.50')}
        >
          Comandos y Acciones
        </chakra.h1>
        <chakra.h2
          margin={'auto'}
          width={'70%'}
          fontWeight={'medium'}
          color={useColorModeValue('gray.500', 'gray.400')}
        >
          Como utilizar el{' '}
          <chakra.strong color={useColorModeValue('gray.700', 'gray.50')}>
            Putibot
          </chakra.strong>{' '}
          y maximizar tus ventas.
        </chakra.h2>
      </Box>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        spacing={'20'}
        mt={16}
        mx={'auto'}
      >
        {testimonials.map((cardInfo, index) => (
          <TestimonialCard {...cardInfo} index={index} />
        ))}
      </SimpleGrid>
      <Box>
        <Icon viewBox="0 0 40 35" mt={14} boxSize={10} color={'pink.400'}>
          <path
            fill={'currentColor'}
            d="M10.7964 5.04553e-07C8.66112 -0.000123335 6.57374 0.632971 4.79827 1.81922C3.0228 3.00547 1.63898 4.69158 0.82182 6.66433C0.00466116 8.63708 -0.209132 10.8079 0.207477 12.9021C0.624087 14.9964 1.65239 16.9201 3.16233 18.4299L19.1153 34.3828C19.2395 34.5074 19.3871 34.6062 19.5496 34.6736C19.7121 34.741 19.8863 34.7757 20.0622 34.7757C20.2381 34.7757 20.4123 34.741 20.5748 34.6736C20.7373 34.6062 20.8848 34.5074 21.0091 34.3828L36.962 18.4272C38.9319 16.3917 40.0228 13.6636 39.9996 10.8311C39.9764 7.99858 38.8409 5.28867 36.838 3.28573C34.835 1.28279 32.1251 0.147283 29.2926 0.124081C26.4601 0.100879 23.732 1.19184 21.6965 3.1617L20.0622 4.79337L18.4305 3.1617C17.4276 2.15892 16.237 1.36356 14.9267 0.821064C13.6163 0.278568 12.2119 -0.000433066 10.7937 5.04553e-07H10.7964Z"
          />
        </Icon>
      </Box>
    </Flex>
  );
}

import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import InicioLoginRegister from './InicioLoginRegister';
import Integracion from './Integracion';
import Pricing from './Pricing';
import Auth from './Auth';
import NickAvatarFrase from './NickAvatarFrase';
import ProfileScreen from './ProfileScreen';
import LinksCreadora from './LinksCreadora';
import Home from './Home';
import Home2 from './Home2';
import Home3 from './Home3';
import Home4 from './Home4';
import Ruleta from './Ruleta';
import MercadoPagoCruzado from './MercadoPagoCruzado';
import AyudaComandos from './AyudaComandos';

import { AnimatePresence } from 'framer-motion';

export default function AnimatedRouters() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.path}>
        <Route path="/" element={<Home />} />
        <Route path="/2" element={<Home2 />} />
        <Route path="/3" element={<Home3 />} />
        <Route path="/4" element={<Home4 />} />
        <Route path="/pagos-cruzados" element={<MercadoPagoCruzado />} />
        <Route path="/ayuda-comandos" element={<AyudaComandos />} />

        <Route path="/ruleta" element={<Ruleta />} />

        <Route path="/login" element={<InicioLoginRegister />} />
        <Route path="/metodos-de-pago" element={<Integracion />} />
        {/* <Route path="/nick-avatar" element={<ProfileScreen />} />
        <Route path="/pricing" element={<Pricing />} />
  <Route path="/entrar" element={<LinksCreadora />} /> */}
        <Route path="/auth" element={<Auth />} />
      </Routes>
    </AnimatePresence>
  );
}

import { useState, useEffect } from 'react';

import { Wheel } from 'react-custom-roulette';

import { Button, VStack } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxios from '../hooks/useAxios';

const CustomWheel = props => {
  const [pais, setPais] = useState();
  var dataRuleta = [
    {
      option: 'Video Chat ' + props.minChat,
      style: { textColor: 'black', backgroundColor: '#EDBD50' }, // 1%
    },
    { option: 'Sexting ' + props.minSext }, //60%
    { option: 'Pack fotos' }, // 70%
    { option: 'Pack videos' }, //60%
    { option: 'Dick Rate' }, //75%
  ];

  useEffect(() => {
    console.log(props);

    console.log(pais);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const [ruleta, setRuleta] = useState(dataRuleta);

  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/getruleta/' + props.paymentid + '/' + localStorage.pais,
  });

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [disable, setDisable] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const backgroundColors = ['#EED9F2', '#343036', '#B65796'];
  const textColors = ['#B65796', '#EDBD50', '#EED9F2'];
  const outerBorderColor = '#eeeeee';
  const outerBorderWidth = 20;
  const innerBorderColor = '#30261a';
  const innerBorderWidth = 10;
  const innerRadius = 20;
  const radiusLineColor = '#30261a';
  const radiusLineWidth = 8;
  const fontSize = 17;
  const textDistance = 60;
  const spinDuration = 1.0;

  useEffect(() => {
    if (response === 'error') navigate('/');
    if (response !== null) {
      setRuleta(dataRuleta);
      console.log(dataRuleta);
      localStorage.creadora = response.id_creadora;
      localStorage.premio = response.priceNumber;
      localStorage.hash = response.hash;
    }
  }, [ruleta, setRuleta]);

  const handleSpinClick = () => {
    localStorage.premio = response.priceNumber;
    localStorage.creadora = response.id_creadora;

    setPrizeNumber(localStorage.premio);
    setMustSpin(true);
    setDisable(true);
  };

  return (
    <VStack spacing={3}>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={ruleta}
        backgroundColors={backgroundColors}
        textColors={textColors}
        fontSize={fontSize}
        outerBorderColor={outerBorderColor}
        outerBorderWidth={outerBorderWidth}
        innerRadius={innerRadius}
        innerBorderColor={innerBorderColor}
        innerBorderWidth={innerBorderWidth}
        radiusLineColor={radiusLineColor}
        radiusLineWidth={radiusLineWidth}
        spinDuration={spinDuration}
        // perpendicularText
        textDistance={textDistance}
        onStopSpinning={() => {
          setTimeout(() => {
            NiceModal.show('ruletapremio');
          }, 1000);
          setMustSpin(false);
        }}
      />
      <Button
        disabled={disable}
        backgroundColor={'#EED9F2'}
        onClick={handleSpinClick}
        size="lg"
        color={'black'}
        px={6}
        colorScheme={'pink'}
        bg={'pink.400'}
        _hover={{ bg: 'pink.500', color: 'white' }}
      >
        Tirada
      </Button>
    </VStack>
  );
};

export default CustomWheel;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import '../../node_modules/video-react/dist/video-react.css';
import { Player } from 'video-react';
export default NiceModal.create(({ videosrc = '', poster = '' }) => {
  const modal = useModal();
  return (
    <>
      <Modal
        id="videomodal"
        size="full"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <Box>
              <Player
                playsInline
                poster={poster !== '' ? poster : 'putilogo.png'}
                src={videosrc}
                height={'80%'}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => modal.hide()}>Continuar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  AlertIcon,
  Alert,
  Box,
  Center,
  Image,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
export default NiceModal.create(() => {
  const modal = useModal();
  const [copied, setCopied] = useState(false);
  return (
    <>
      <Modal
        id="qrmodal"
        size="full"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <Box>
              <Center>
                {!copied ? (
                  <Image
                    height={'81vh'}
                    src={'qr.jpg'}
                    onClick={() => {
                      navigator.clipboard.writeText('@app_putibot');
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 3000);
                    }}
                  />
                ) : (
                  <Alert
                    status="success"
                    justifyContent="center"
                    height={'81vh'}
                  >
                    <AlertIcon />
                    <Box>Copiado!</Box>
                  </Alert>
                )}
              </Center>
            </Box>
          </ModalBody>
          <ModalFooter alignItems={'center'}>
            <Box width={'100%'}>
              <Center>
                <Button
                  bg="pink.500"
                  color="white"
                  _hover={{ bg: '#e14cbf' }}
                  width={'300px'}
                  height={'50px'}
                  onClick={() => modal.hide()}
                >
                  Continuar
                </Button>
              </Center>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

import React, { useEffect, useContext, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { userContext } from '../AppContext';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Center,
  VStack,
} from '@chakra-ui/react';
import { BsCheckCircle } from 'react-icons/bs';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

export default function Auth() {
  const { config } = useContext(userContext);
  const { width, height } = useWindowSize();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const navigate = useNavigate();
  const [isRaining, setIsRaining] = useState(true);
  const [accessToken, setAccessToken] = useState('');
  const [isPutiruleta, setIsPutiruleta] = useState(false);
  const [unanueva, setUnaNueva] = useState(false);
  const [ruletahash, setRuletaHash] = useState();
  const [pais, setPais] = useState();
  useEffect(() => {
    setTimeout(() => {
      setIsRaining(false);
    }, 5000);
    let pais;

    if (query.get('code') && query.get('state')) {
      const state = decodeURIComponent(query.get('state'));
      console.log(state);
      const arr_state = state.split('|');
      if (!arr_state[0].includes('@')) {
        var ruleta_hash = arr_state[0].split('-')[1];
        //vengo del putiruleta
        console.log(
          config.url_api_prod +
            '/access-token/' +
            query.get('code') +
            '/no/' +
            state
        );
        axios
          .get(
            config.url_api_prod +
              '/access-token/' +
              query.get('code') +
              '/no/' +
              state
          )
          .then(res => {
            localStorage.setItem('user_id', res.data.id);
            setAccessToken(res.data.access_token);
            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('public_key', res.data.public_key);
            setIsPutiruleta(true);
            setRuletaHash(res.data.putiruleta);
            //clavar unos localstorage y redireccionar
            if (state.includes('recon')) {
              window.location.href =
                'https://putiruleta.com/' +
                res.data.putiruleta +
                '/' +
                btoa(res.data.public_key);
            } else {
              window.location.href =
                'https://putiruleta.com/' +
                res.data.putiruleta +
                '/' +
                btoa(
                  res.data.access_token +
                    '|' +
                    res.data.public_key +
                    '|' +
                    res.data.email +
                    '|' +
                    res.data.nickname
                );
              // navigate('/nick-avatar');
            }
          });
      } else {
        axios
          .get(
            config.url_api_prod +
              '/access-token/' +
              query.get('code') +
              '/' +
              arr_state[0] +
              '/' +
              arr_state[1]
          )
          .then(res => {
            console.log(res.data);
            localStorage.setItem('user_id', res.data.id);
            setAccessToken(res.data.access_token);
            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('public_key', res.data.public_key);
            localStorage.setItem('merchant', res.data);
            localStorage.setItem('hash', query.get('code'));
            localStorage.setItem('pais', arr_state[1]);
            //clavar unos localstorage y redireccionar
            window.close();
            // navigate('/nick-avatar');
          });
      }
    }
  }, []);

  const handleRedirect = () => {
    if (accessToken !== '') {
      window.location.href =
        'https://putiruleta.com/' + ruletahash + '/' + btoa(accessToken);
    } else {
      window.location.href =
        'https://putiruleta.com/' +
        ruletahash +
        '/' +
        btoa(localStorage.access_token);
    }
  };

  const handleClose = () => {
    window.close();
  };

  return isPutiruleta ? (
    <>
      {isRaining && (
        <Confetti width={width} height={height} numberOfPieces={200} />
      )}
      <Box
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={'brand.250'}
        p={16}
      >
        <Stack
          spacing={1}
          w={'full'}
          maxW={'md'}
          bg={'white'}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={1}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            <Center> MercadoPago Conectado!</Center>
          </Heading>
          <FormControl id="email">
            <Box
              style={{ cursor: 'pointer' }}
              variant={'solid'}
              backgroundColor={'transparent'}
              size={'sm'}
              mr={1}
              p={10}
            >
              <BsCheckCircle style={{ fontSize: '71px', color: 'green' }} />
            </Box>

            <Box
              style={{ cursor: 'pointer' }}
              variant={'solid'}
              backgroundColor={'#f0f0f0'}
              size={'sm'}
              mr={5}
              p={2}
            >
              <VStack>
                <small>Redirigiendo a tu ruleta 🍭</small>
              </VStack>
            </Box>
          </FormControl>
        </Stack>
      </Box>
    </>
  ) : (
    <>
      {isRaining && (
        <Confetti width={width} height={height} numberOfPieces={200} />
      )}
      <Box
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={'brand.250'}
        p={16}
      >
        <Stack
          spacing={1}
          w={'full'}
          maxW={'md'}
          bg={'white'}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={1}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            <Center> MercadoPago Conectado!</Center>
          </Heading>
          <FormControl id="email">
            <Box
              style={{ cursor: 'pointer' }}
              variant={'solid'}
              backgroundColor={'transparent'}
              size={'sm'}
              mr={1}
              p={10}
            >
              <BsCheckCircle style={{ fontSize: '71px', color: 'green' }} />
            </Box>

            <Box
              style={{ cursor: 'pointer' }}
              variant={'solid'}
              backgroundColor={'#f0f0f0'}
              size={'sm'}
              mr={5}
              p={2}
              onClick={() => handleClose()}
            >
              <small>Listo para vender en Argentina y Uruguay!</small>
              <br />
              <small>Proximamente Chile y Brasil.</small>
            </Box>
          </FormControl>
        </Stack>
      </Box>
    </>
  );
}

import {
  Container,
  Stack,
  Text,
  Button,
  Image,
  Circle,
  Center,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';

import { AiOutlineQrcode } from 'react-icons/ai';

export default function Home() {
  useEffect(() => {
    if (!localStorage.solounavez) {
      setTimeout(() => {
        NiceModal.show('concurso', {
          src: 'concurso.gif',
        });
      }, 3000);
      localStorage.solounavez = true;
    }
  }, []);

  const navigate = useNavigate();
  const showQrModal = () => {
    NiceModal.show('qrmodal');
  };

  return (
    <Container maxW={'5xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        p={{ base: '15px 0 0', md: '15px 0 0' }}
      >
        <Text
          as={'span'}
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          fontWeight={600}
        >
          Ventas Automatizadas
        </Text>

        <Text
          color={'pink.500'}
          fontWeight={800}
          fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
        >
          En Tu Canal
        </Text>
        <Text>(Telegram)</Text>
      </Stack>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 17, md: 23 }}
      >
        <Text
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          color={'gray.500'}
          maxW={'3xl'}
        >
          No más tareas repetitivas de forma manual.
        </Text>
        <Stack spacing={6} direction={'row'}>
          <Button
            rounded={'full'}
            px={6}
            colorScheme={'pink'}
            bg={'pink.400'}
            _hover={{ bg: '#95def7' }}
            onClick={() => {
              showQrModal();
            }}
          >
            <AiOutlineQrcode style={{ fontSize: '30px' }} />
          </Button>
          <Button
            rounded={'full'}
            px={6}
            colorScheme={'white'}
            bg={'black'}
            _hover={{ bg: '#95def7' }}
            onClick={() => {
              navigate('/2');
            }}
          >
            c&oacute;mo?
          </Button>
        </Stack>

        <Center>
          <VStack>
            <HStack>
              <Circle
                size="40px"
                bg="gray"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/');
                }}
              >
                <Text>
                  <VscChevronLeft />
                </Text>
              </Circle>
              <Circle
                size="40px"
                bg="black"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/');
                }}
              >
                <Text>1</Text>
              </Circle>
              <Circle
                size="40px"
                bg="pink.500"
                color="white"
                _hover={{ bg: '#95def7' }}
                size="40px"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/2');
                }}
              >
                <Text>2</Text>
              </Circle>
              <Circle
                size="40px"
                bg="pink.500"
                color="white"
                _hover={{ bg: '#95def7' }}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/3');
                }}
              >
                <Text>3</Text>
              </Circle>
              <Circle
                size="40px"
                bg="pink.500"
                color="white"
                _hover={{ bg: '#95def7' }}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/4');
                }}
              >
                <Text>4</Text>
              </Circle>
              <Circle
                size="40px"
                bg="gray"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/2');
                }}
              >
                <Text>
                  <VscChevronRight />
                </Text>
              </Circle>
            </HStack>
            <Image src="upa.PNG" />
          </VStack>
        </Center>
      </Stack>
    </Container>
  );
}

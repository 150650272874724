import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  ButtonGroup,
  VStack,
} from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import axios from 'axios';
import { useEffect } from 'react';
import useAxios from '../hooks/useAxios';

export default NiceModal.create(({ props }) => {
  const dataRuleta = [
    'Minutos de Video Chat',
    'Sexting',
    'Un Pack Fotos',
    'Un Pack videos',
    'DickRate',
  ];
  const modal = useModal();
  const [copied, setCopied] = useState(false);
  const handleEntrarClick = () => {};
  const { width, height } = useWindowSize();
  const [isRaining, setIsRaining] = useState(true);
  const [clicked, setClicked] = useState(false);

  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/got-email/' + localStorage.codigo,
  });

  const handleRedirect = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 3000);
    window.location.href =
      'https://putipack.com/procesar/?payment_id=' +
      localStorage.codigo +
      '&ruleta=true';
  };

  const [input, setInput] = useState('');

  const handleInputChange = e => setInput(e.target.value);

  const guardarMail = (mail = '') => {
    axios.post('https://server-mb9m.onrender.com/guardar-mail', {
      //axios.post('http://localhost:3005/guardar-mail', {
      email: mail !== '' ? mail : input,
      payment_id: localStorage.codigo,
    });
  };
  const isError = input === '';

  const [needEmail, setNeedEmail] = useState(true);

  useEffect(() => {
    console.log('averrr', localStorage.mail !== undefined);
    localStorage.setItem('tirada', 'true|' + localStorage.codigo);

    if (response !== null) {
      console.log('la response ', response);
      if (response !== 'nomail') {
        localStorage.mail = response;
        setNeedEmail(false);
      }
      if (response === 'nomail') {
        if (localStorage.mail !== undefined) {
          guardarMail(localStorage.mail);
          setNeedEmail(false);
        }
      }
    }
  });

  return (
    <>
      {isRaining && (
        <Confetti
          colors={['#E098C8', '#76603B', '#B65494', '#66646A']}
          width={width}
          height={height}
          numberOfPieces={200}
        />
      )}
      <Modal
        id="ruletapremio"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <Box>
              <Center>
                {!copied ? (
                  <Box margin={'20% auto 30%'}>
                    <VStack>
                      <Text
                        fontWeight={'700'}
                        fontSize={{ base: '3em', md: '3em', lg: '5x1' }}
                      >
                        Ganaste
                      </Text>
                      <Text
                        fontWeight={'700'}
                        fontSize={{ base: '2x1', md: '3x1', lg: '4x1' }}
                      >
                        ¡{dataRuleta[localStorage.premio]}!
                      </Text>
                      {needEmail ? (
                        <FormControl isInvalid={isError}>
                          <FormLabel>Email</FormLabel>
                          <ButtonGroup isAttached variant="outline">
                            <Input
                              type="email"
                              value={input}
                              onChange={handleInputChange}
                            />
                            <Button
                              colorScheme={'pink'}
                              bg="pink.500"
                              color="white"
                              fontSize={{
                                base: '0.8em',
                                sm: '0.8em',
                                md: '0.8em',
                                lg: '0.8em',
                              }}
                              _hover={{ bg: '#e14cbf' }}
                              onClick={() => {
                                guardarMail();
                                setNeedEmail(false);
                              }}
                            >
                              Continuar
                            </Button>
                          </ButtonGroup>
                          {!isError ? (
                            <FormHelperText>
                              Ingresa tu email para continuar.
                            </FormHelperText>
                          ) : (
                            <FormErrorMessage>Obligatorio.</FormErrorMessage>
                          )}
                        </FormControl>
                      ) : localStorage.premio == 0 ||
                        localStorage.premio == 1 ||
                        localStorage.premio == 4 ? (
                        <>
                          <Text>
                            Reclama tu premio con el siguiente codigo{' '}
                          </Text>
                          <Alert
                            style={{ marginTop: '45px' }}
                            status="info"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                            width={'400px'}
                          >
                            <FaCopy
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  localStorage.codigo
                                );
                                setCopied(true);
                                setTimeout(() => {
                                  setCopied(false);
                                }, 3000);
                              }}
                            />
                            <Box
                              paddingLeft={5}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  localStorage.codigo
                                );
                                setCopied(true);
                                setTimeout(() => {
                                  setCopied(false);
                                }, 3000);
                              }}
                            >
                              {localStorage.codigo}
                            </Box>
                          </Alert>
                        </>
                      ) : (
                        <Center style={{ paddingTop: '50px' }}>
                          <Button
                            rounded={'full'}
                            px={6}
                            colorScheme={'pink'}
                            size="40px"
                            bg="pink.500"
                            color="white"
                            _hover={{ bg: '#e14cbf' }}
                            style={{
                              width: '300px',
                              height: '70px',
                            }}
                            isLoading={clicked}
                            loadingText="Descargando"
                            onClick={() => {
                              handleRedirect();
                            }}
                          >
                            Descargar
                          </Button>
                        </Center>
                      )}
                    </VStack>
                  </Box>
                ) : (
                  <>
                    <Box margin={'20% auto 30%'}>
                      <VStack>
                        <Text
                          fontWeight={'700'}
                          fontSize={{ base: '3em', md: '3em', lg: '5x1' }}
                        >
                          Ganaste
                        </Text>
                        <Text
                          fontWeight={'700'}
                          fontSize={{ base: '2x1', md: '3x1', lg: '4x1' }}
                        >
                          ¡{dataRuleta[localStorage.premio]}!
                        </Text>
                        <Text>-</Text>
                      </VStack>
                      <Alert
                        marginTop={'45px'}
                        status="success"
                        justifyContent="center"
                        width={'400px'}
                      >
                        <AlertIcon />
                        <Box>Copiado!</Box>
                      </Alert>
                    </Box>
                  </>
                )}
              </Center>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

import {
  Container,
  Stack,
  Text,
  Button,
  Image,
  Center,
  Circle,
  VStack,
  HStack,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineQrcode } from 'react-icons/ai';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';

export default function Home3() {
  const navigate = useNavigate();
  const showVideoModal = () => {
    NiceModal.show('videomodal', { videosrc: 'ultiputi.mp4' });
  };
  const showQrModal = () => {
    NiceModal.show('qrmodal');
  };

  return (
    <Container maxW={'5xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        p={{ base: '15px 0 0', md: '15px 0 0' }}
      >
        <Text
          color={'pink.500'}
          fontWeight={800}
          fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
        >
          Putibot.app
        </Text>
        <small>(beta)</small>
        <Text></Text>
      </Stack>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 17, md: 23 }}
      >
        <Text
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          color={'gray.500'}
          maxW={'3xl'}
        >
          {
            //bir ü, é, á, í, ó, ú, ñ, Ñ¡ !
          }
          ¿Cuál es nuestra Comisión?
        </Text>
        <Text
          color={'gray.500'}
          padding={{
            base: '0 50px',
            sm: '0 60px',
            md: '0 80px',
            lg: '0 180px',
          }}
          fontSize={{ base: 'sm', sm: 'sm', md: 'md', lg: 'lg' }}
        >
          ¡Es apenas de 0.5 dolares por cada transaccion!
        </Text>
        <Stack spacing={6} direction={'row'}>
          <Button
            rounded={'full'}
            px={6}
            colorScheme={'pink'}
            bg={'pink.400'}
            _hover={{ bg: '#95def7' }}
            onClick={() => {
              showQrModal();
            }}
          >
            <AiOutlineQrcode style={{ fontSize: '30px' }} />
          </Button>
          <Button
            rounded={'full'}
            px={6}
            colorScheme={'white'}
            bg={'black'}
            _hover={{ bg: '#95def7' }}
            onClick={showVideoModal}
          >
            Demo Creadora
          </Button>
        </Stack>
        <Center>
          <VStack>
            <HStack>
              <Circle
                size="40px"
                bg="gray"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/2');
                }}
              >
                <Text>
                  <VscChevronLeft />
                </Text>
              </Circle>
              <Circle
                size="40px"
                bg="pink.500"
                color="white"
                _hover={{ bg: '#95def7' }}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/');
                }}
              >
                <Text>1</Text>
              </Circle>
              <Circle
                size="40px"
                bg="pink.500"
                color="white"
                _hover={{ bg: '#95def7' }}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/2');
                }}
              >
                <Text>2</Text>
              </Circle>
              <Circle
                size="40px"
                bg="black"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/3');
                }}
              >
                <Text>3</Text>
              </Circle>
              <Circle
                size="40px"
                bg="pink.500"
                color="white"
                _hover={{ bg: '#95def7' }}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/4');
                }}
              >
                <Text>4</Text>
              </Circle>
              <Circle
                size="40px"
                bg="gray"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/4');
                }}
              >
                <Text>
                  <VscChevronRight />
                </Text>
              </Circle>
            </HStack>
            <Image src="workin.PNG" />
          </VStack>
        </Center>
      </Stack>
    </Container>
  );
}
